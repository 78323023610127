import { useState, useContext } from 'react'
import baseurl from '../../../utilities/api'
import { UserContext } from '../../../contexts/user';
import Loader from '../../loader/loader';
import * as amplitude from '@amplitude/analytics-browser';

function EmailCheck(props) {
    const [email, setEmail] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { user, setUser } = useContext(UserContext);

    const userCheck = (e) => {
        e.preventDefault()
        amplitude.track('Email Entered');
        setLoading(true)
        setUser({ email, ...user })
        const hostB64 = btoa(window.location.host); 
        fetch(`${baseurl}/api/student/userbyemail?` + new URLSearchParams({
            email: email
        }), {
            headers: {
                Authorization: `Bearer ${hostB64}`
            }
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error("There's been a problem with that email account");
                }
                })
            .then(
                (result) => {
                    if (result.result) {
                        props.setView('login')
                    } else {
                        props.setView('newuser')
                    }
                },
                (error) => {
                    console.log(error);
                    setLoading(false)
                    setError("There's a problem with that email account - please get in touch")
                }
            )
    }

    if (!loading) {
        return (
            <form id='check-email' onSubmit={userCheck}>
                <div className='inputWrapper'>
                    <label>What is your email address?</label>
                    <input onChange={(e) => setEmail(e.target.value)} id='triba-email' type='email' placeholder="your@email.co" autoFocus />
                </div>
                <p>{error}</p>
                <button type='submit'>Next</button>
            </form>
        )
    }

    return <Loader />

}

export default EmailCheck;