import React, { useContext } from "react";
import { UserContext } from '../../contexts/user';
import Svg from "../common/svg";

function Success() {
    const { user } = useContext(UserContext);

    return (
        <div id='triba-success'>
            <Svg width="40" height="40">
                <circle fill="none" stroke="#4caf50" strokeWidth="2.5" cx="20" cy="20" r="19" strokeLinecap="round" transform="rotate(-9 20 20)" className="circle" />
                <polyline fill="none" stroke="#4caf50" strokeWidth="3" points="8.8,21.4 17.3,28.4 30.4,13.8" strokeLinecap="round" strokeLinejoin="round" className="tick" />
            </Svg>
            <h3>Your booking is confirmed</h3>
            <p>A confirmation email is on its way. <span>{user.email}</span>.</p>
        </div>
    )
}

export default Success;