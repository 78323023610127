import { useContext, useState, useEffect } from 'react'
import ClassInformation from '../class-information/class-information';
import Login from './forms/login';
import EmailCheck from './forms/email-check'
import Waiver from './forms/waiver'
import Member from './forms/member'
import Pass from './forms/pass'
import NewUser from './forms/new-user.js'
import Payment from './forms/payment.js'
import Success from './success.js'
import Capacity from './capacity';
import Loader from '../loader/loader';

import { UserContext } from '../../contexts/user';
import { ClassContext } from '../../contexts/class';
import { checkCapacity } from '../../utilities/api'

import './bookingform.css';

function BookingForm() {
    const { user } = useContext(UserContext);
    const { tribaClass } = useContext(ClassContext);
    const [email, setEmail] = useState(undefined)
    const [capacity, setCapacity] = useState(undefined)
    const [validMembership, setValidMembership] = useState(undefined)
    const [view, setView] = useState('loading')

    useEffect(() => {
        async function getCapacity() {
            return await checkCapacity(tribaClass)
        }

        getCapacity()
            .then((res) => {
                // Todo: Class capacity should be stored in the class context
                const capacity = res.capacity
                setCapacity(capacity)
                if (capacity <= 0) {
                    setView('capacity')
                    return
                }

                if (user?.id) {
                    // if tribaClass.type is a workshop
                    if(tribaClass.type === 1) {
                        console.log('this is a workshop');
                        setView('payment')
                        return
                    }

                    let validMember = false;
                    let validPass = false;

                    if (user.memberships?.length > 0) {
                        for (const membership of user.memberships) {
                            const now = Date.now()
                            if (membership.cancelled !== null) {
                                if (membership.cancelled < now) {
                                    continue
                                }
                            }
                            for (const membershipClass of membership.classes) {
                                if (membershipClass.type === tribaClass.classtype) {
                                    if (membershipClass.class_id === tribaClass.id || membershipClass.class_id === -1) {
                                        validMember = true
                                        setValidMembership(membership)
                                        break
                                    }
                                }
                            }
                            if (validMember) {
                                break
                            }
                        }
                    }

                    if (!validMember) {
                        if (user.passes?.length > 0) {
                            for (const pass of user.passes) {
                                if (pass.valid) {

                                    const passExpiry = new Date(pass.expiry_date).setUTCHours(23,59,59,999);
                                    const classDate = new Date(tribaClass.selectedDate);

                                    if (passExpiry < classDate) {
                                        break
                                    }

                                    switch (tribaClass.classtype) {
                                        case 'stream':
                                            if (pass.online_price_state === 1) {
                                                validPass = true
                                            }
                                            break;
                                        case 'studio':
                                            if (pass.studio_price_state === 1) {
                                                validPass = true
                                            }
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                if (validPass) {
                                    break
                                }
                            }
                        }
                    }
                    
                    if (validMember) {
                        setView('member')
                    } else if (validPass) {

                        setView('pass')
                    } else {
                        setView('payment')
                    }
                } else {
                    setView('emailcheck')
                }
            })
        // eslint-disable-next-line
    }, [])

    let formView
    let success = false

    switch (view) {
        case 'loading':
            formView = <Loader />
            break;
        case 'emailcheck':
            formView = <EmailCheck email={email} setEmail={setEmail} setView={setView} />
            break;
        case 'login':
            formView = <Login setView={setView} setValidMembership={setValidMembership} />
            break;
        case 'newuser':
            formView = <NewUser setView={setView} />
            break;
        case 'waiver':
            formView = <Waiver setView={setView} />
            break;
        case 'payment':
            formView = <Payment setView={setView} selectedClass={tribaClass} user={user} />
            break;
        case 'pass':
            formView = <Pass user={user} setView={setView} selectedClass={tribaClass} />
            break;
        case 'member':
            formView = <Member user={user} setView={setView} selectedClass={tribaClass} membership={validMembership} />
            break;
        case 'success':
            formView = <Success capacity={capacity} setCapacity={setCapacity} />
            success = true
            break;
        case 'capacity':
            formView = <Capacity />
            break;
        default:
            formView = <Loader />
            break;
    }

    if (tribaClass) {
        return (
            <div id='triba-booking-form' className={view}>
                <ClassInformation capacity={capacity} success={success} />
                <div>
                    {formView}
                </div>
            </div>
        )
    } else {
        return <Loader />
    }
}

export default BookingForm;