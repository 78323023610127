import { useState, useContext, useEffect } from 'react'
import { UserContext } from '../../../contexts/user';
import { ClassContext } from '../../../contexts/class';

import Loader from '../../loader/loader';

function NewUser(props) {
    const [firstname, setFirstname] = useState(undefined)
    const [lastname, setLastname] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const { user, setUser } = useContext(UserContext);
    const { setTribaClass, tribaClass } = useContext(ClassContext);

    useEffect(() => {
        const newClass = { ...tribaClass, intro: true }
        setTribaClass(newClass)
        // eslint-disable-next-line
    }, [])

    const createUser = async (e) => {
        e.preventDefault()
        setUser({ firstname, lastname, password, new_user: true, ...user })
        props.setView('waiver')
        setLoading(false)
    }

    if (!loading) {
        return (
            <form onSubmit={createUser} id='triba-newuser'>
                <div className='inputWrapper'>
                    <label>
                        Email Address?</label>
                    <input type='email' value={user.email} placeholder="your@email.co" readOnly disabled required />
                </div>
                <div className='inputWrapper'>
                    <label>First Name?</label>
                    <input onChange={(e) => setFirstname(e.target.value)} type='text' placeholder="Your first name" autoFocus required />
                </div>
                <div className='inputWrapper'>
                    <label>Last Name?</label>
                    <input onChange={(e) => setLastname(e.target.value)} type='text' placeholder="Your last name" required />
                </div>
                <div className='inputWrapper'>
                    <label>A Password</label>
                    <input onChange={(e) => setPassword(e.target.value)} type='password' placeholder="********" required />
                </div>
                <button type='submit'>Go to Payment</button>
            </form>
        )
    }

    return (<Loader />)
}

export default NewUser;