import { useContext } from 'react'
import { UserContext } from '../../../contexts/user';
import { createBooking } from '../../../utilities/api';
import Diamond from '../../icons/diamond/diamond';
import * as amplitude from '@amplitude/analytics-browser';

function Member(props) {
    const { user, logout } = useContext(UserContext);
    const { selectedClass, setView, membership } = props

    const logoutuser = () => {
        logout()
        props.setView('emailcheck')
    }

    const bookClass = async (event) => {
        event.preventDefault();
        let bookingObject = {
            userid: user.id,
            clientSecret: `membership_${user.memberships[0].id}`,
            paymentIntent: `membership_${user.memberships[0].id}`,
            amount: 0,
            ...selectedClass
        }

        await createBooking(bookingObject)
        setView('success')

    }
    if (!membership) {
        return <p>Loading</p>
    }

    return (
        <form 
            id='triba-paymentform' 
            className='triba-membership-paymentform' 
            onSubmit={(event) => { 
                amplitude.track('Booked', {type: 'membership'});
                bookClass(event) 
            }}
        >
            <Diamond />
            <p>Hi, <strong>{user.firstname}</strong></p>
            <p><span>{user.email}</span></p>
            <button className='triba-tertiary' onClick={() => { logoutuser() }}>Not you?</button>
            <p>This class is included in your membership.</p>
            <p><span>{membership.name}</span></p>
            <button type='submit'>Book</button>
        </form>
    )
}

export default Member;