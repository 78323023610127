import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../../../contexts/user';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createUser, createPaymentIntent } from '../../../utilities/api';
import Loader from "../../loader/loader";
import CheckoutForm from "./checkout-form";

function Payment(props) {
    const { user, setUser } = useContext(UserContext);
    const { selectedClass } = props;
    const [clientSecret, setClientSecret] = useState(undefined);
    const [stripePromise, setStripePromise] = useState(undefined);
    const [paymentIntent, setPaymentIntent] = useState(undefined);

    const logout = () => {
        setUser(null);
        props.setView('emailcheck')
        document.cookie = 'triba=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
    
    useEffect(() => {
        async function setupPaymentIntent() {
            let student = user
            if (user.new_user) {
                const newUser = await createUser(user)
                newUser.student.new_user = false
                setUser(newUser.student)
                student = newUser.student
            }

            const paymentIntent = await createPaymentIntent(selectedClass, student)
            const stripe = loadStripe(paymentIntent.stripeKey)
            setStripePromise(stripe);
            setClientSecret(paymentIntent.clientSecret)
            setPaymentIntent(paymentIntent.paymentIntent)
        }

        setupPaymentIntent()

    }, [selectedClass]);

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

    if (clientSecret) {
        return (
            <div id='triba-paymentform'>
                <p>Hi, <strong>{user.firstname}</strong></p>
                <p><span>{user.email}</span></p>
                <button className='triba-tertiary' onClick={() => { logout() }}>Not you?</button>
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm selectedClass={selectedClass} user={user} clientSecret={clientSecret} paymentIntent={paymentIntent} setView={props.setView} />
                </Elements>
            </div>
        )
    } else {
        return <Loader />
    }

}

export default Payment;