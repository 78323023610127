const baseurl = process.env.NODE_ENV === 'production' ? 'https://api.triba.tech' : 'https://localhost:8443';
export default baseurl;
const hostB64 = btoa(window.location.host);
export const session = async () => {
    const url = new URL(`${baseurl}/api/auth/session`)
    const session_token = window.sessionStorage.getItem("triba");
    const params = { session_token }
    url.search = new URLSearchParams(params).toString(); 

    return fetch(url, {
        headers: {
            Authorization: `Bearer ${hostB64}`
        }
    })
    .then((res) => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error(res);
        }

    })
    .then(
        (result) => {
            return result.user
        },
        (error) => {
            console.log(error)
        }
    )
};

export const createBooking = async (booking) => {
    return fetch(`${baseurl}/api/bookings/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${hostB64}`
              
        },
        body: JSON.stringify(booking)
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }

        })
        .then(
            (result) => {
                return result
            },
            (error) => {
                throw error
            }
        )
}

export const checkCapacity = async (booking) => {
    return fetch(`${baseurl}/api/bookings/capacity?` + new URLSearchParams(
        booking
    ), {
        headers: {
            Authorization: `Bearer ${hostB64}`
        }
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }
        })
        .then(
            (result) => {
                return result
            },
            (error) => {
                throw error
            }
        )
}

export const createUser = async (user) => {
    return fetch(`${baseurl}/api/student/create`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${hostB64}`
            
        },
        body: JSON.stringify(user)
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }
        })
        .then((result) => {
            return result
        },
            (error) => {
                console.log(error)
            }
        )
}

export const payWithPass = async (pass, userid) => {
    return fetch(`${baseurl}/api/payments/pass`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${hostB64}`
        },
        body: JSON.stringify({ pass, userid })
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw new Error(res);
            }

        })
        .then((result) => {
            return result
        },
        (error) => {
            throw error
        })
}

export const createPaymentIntent = async (selectedClass, student) => {
    return fetch(`${baseurl}/api/payments/create-payment-intent`, {
        method: "POST",
        headers: { 
            "Content-Type": "application/json",
            "Authorization": `Bearer ${hostB64}`
            
        },
        body: JSON.stringify({ 
            items: [selectedClass], 
            student,
            meta : {
                type: 'class',
                class_id: selectedClass.classid,
                class_date: selectedClass.selectedDate,
                timeslot_id: selectedClass.tid,
                class_type: selectedClass.classtype,
            }
        }),
    })
    .then((res) => res.json())
    .then((data) => {
        return data
    });
}