import { createContext, useState } from "react";
import PropTypes from 'prop-types';

export const ClassContext = createContext()
export const ClassConsumer = ClassContext.Consumer

const ClassContextProvider = ({ children }) => {
    const calendarType = process.env.REACT_APP_CALENDAR
    const [tribaClass, setTribaClass] = useState(null)
    const [classtype, setClasstype] = useState(calendarType || 'studio')

    const toggleClassType = () => {
        let type = 'stream'

        if (classtype === 'stream') {
            type = 'studio'
        }

        setClasstype(type)
        setTribaClass(null)
    }

    return (
        <ClassContext.Provider value={{
            setTribaClass,
            setClasstype,
            toggleClassType,
            classtype,
            tribaClass
        }}>{children}</ClassContext.Provider>
    );
}

ClassContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ClassContextProvider