import { createContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import baseurl, { session } from '../../utilities/api'

export const UserContext = createContext()
export const UserConsumer = UserContext.Consumer

const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)

    const logout = () => {
        setUser(null);
        const hostB64 = btoa(window.location.host); 
        document.cookie = 'triba=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        fetch(`${baseurl}/api/auth/logout`, {
            headers: {
                Authorization: `Bearer ${hostB64}`
            }
        })
            .then(res => res.json())
            .then(
                (result) => {

                },
                (error) => {

                }
            )
    }

    useEffect(() => {
        async function getSession() {
            try {
                const user = await session();
                setUser(user)
            } catch (error) {
                console.log("We've got a problem connecting");
            }
        }

        getSession()

    }, [])

    return (<UserContext.Provider value={{ user, setUser, logout }}>{children}</UserContext.Provider>);
}

UserContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default UserContextProvider