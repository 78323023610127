import Svg from "../../common/svg";

function Diamond(props) {
    return (
        <Svg viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
            <g><path d="M990,330.6l-34.6-40.8h-1l1-0.8l-133-164.4h0L794,83.9H204.5l-28.7,40.8h0.1l0,0L43.1,289l1.1,0.9h-1.1L10,330.6l461.8,585.5h28.3h28.3l0-0.1L990,330.6z M898.8,289.9H689l104.9-129.8L898.8,289.9z M604,289.9H394.1L499,160.1L604,289.9z M765.3,124.7L765.3,124.7L646.5,271.8l-119-147.1H765.3z M470.5,124.7L351.8,271.6L232.9,124.7L470.5,124.7L470.5,124.7z M204.5,160l104.9,129.8H99.5L204.5,160z M66.8,330.6h866.3l-433,549.6l0-0.1L66.8,330.6z" /><path d="M623.6,330.5L500.4,837.9L377.3,330.5h-45.8l117.8,485.1l50.7,64.4l0,0.1l0.4-0.5l0.4,0.5l0-0.1l50.7-64.4l117.8-485.1H623.6z" /></g>
        </Svg>
    )
}

export default Diamond;