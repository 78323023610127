import { useContext } from 'react'
import { ClassContext } from '../../contexts/class'
import Svg from '../common/svg'

function CalendarHeader(props) {
    const { changeDate, date } = props // setDate
    const { toggleClassType } = useContext(ClassContext)
    const calendarType = process.env.REACT_APP_CALENDAR

    let weekdate = date.addDays(6)
    // <input
    //     type="date"
    //     name="calendardate"
    //     value={date.toISOString().replace(/T.*/, '').split('-').join('-')}
    //     onChange={e => changeDate(new Date(e.target.value))}
    // />
    return (
        <div className='triba-calendar-header'>
            <div className='triba-calendar-dateselector'>
                <button onClick={() => { changeDate("down") }} className='calendartoggle'>
                    <Svg viewBox="0 0 180 330">
                        <title>decrease date</title>
                        <g stroke="#000" strokeWidth="10" fill="none" fillRule="evenodd">
                            <g id="up-arrow-svgrepo-com" transform="translate(90.000000, 165.000000) rotate(-90.000000) translate(-90.000000, -165.000000) translate(-75.000000, 75.000000)" fill="#000000" fillRule="nonzero">
                                <path d="M325.606,154.393 L175.602,4.393 C172.79,1.58 168.974,0 164.996,0 C161.017,0 157.202,1.581 154.389,4.394 L4.393,154.394 C-1.465,160.252 -1.465,169.749 4.393,175.607 C10.25,181.464 19.748,181.465 25.606,175.607 L164.996,36.214 L304.393,175.607 C307.322,178.536 311.161,180.000125 315,180.000125 C318.839,180.000125 322.678,178.536 325.607,175.606 C331.464,169.748 331.464,160.251 325.606,154.393 Z" id="XMLID_224_"></path>
                            </g>
                        </g>
                    </Svg>
                </button>
                <p>{date.getOrdinalDate()} - {weekdate.getOrdinalDate()} {weekdate.getMonthName()}</p>
                <button onClick={() => { changeDate("up") }} className='calendartoggle'>
                    <Svg viewBox="0 0 180 330">
                        <title>increase date</title>
                        <g stroke="#000" strokeWidth="10" fill="none" fillRule="evenodd">
                            <g id="up-arrow-svgrepo-com" transform="translate(90.000000, 165.000000) rotate(-270.000000) translate(-90.000000, -165.000000) translate(-75.000000, 75.000000)" fill="#000000" fillRule="nonzero">
                                <path d="M325.606,154.393 L175.602,4.393 C172.79,1.58 168.974,0 164.996,0 C161.017,0 157.202,1.581 154.389,4.394 L4.393,154.394 C-1.465,160.252 -1.465,169.749 4.393,175.607 C10.25,181.464 19.748,181.465 25.606,175.607 L164.996,36.214 L304.393,175.607 C307.322,178.536 311.161,180.000125 315,180.000125 C318.839,180.000125 322.678,178.536 325.607,175.606 C331.464,169.748 331.464,160.251 325.606,154.393 Z" id="XMLID_224_"></path>
                            </g>
                        </g>
                    </Svg>
                </button>
            </div>
            {!calendarType &&
                <div id="class_type_toggle">
                    <span className='switchlabel selected'>Studio</span>
                    <input type="checkbox" id="calendar_switch" onChange={toggleClassType} />
                    <label htmlFor="calendar_switch" id="calendar_switch_label">Toggle</label>
                    <span className='switchlabel'>Stream</span>
                </div>
            }

        </div>
    )
}

export default CalendarHeader;