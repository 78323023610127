// eslint-disable-next-line no-extend-native
Date.prototype.getOrdinalDate = function () {
    let d = this.getDate();
    if (d > 3 && d < 21) return d + 'th';
    switch (d % 10) {
        case 1: return d + "st";
        case 2: return d + "nd";
        case 3: return d + "rd";
        default: return d + "th";
    }
};
// eslint-disable-next-line no-extend-native
Date.prototype.getMonthName = function (shorten) {
    var monthsNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthIndex = this.getMonth();
    var tempIndex = -1;
    if (monthIndex === 0) { tempIndex = 0 };
    if (monthIndex === 1) { tempIndex = 1 };
    if (monthIndex === 2) { tempIndex = 2 };
    if (monthIndex === 3) { tempIndex = 3 };
    if (monthIndex === 4) { tempIndex = 4 };
    if (monthIndex === 5) { tempIndex = 5 };
    if (monthIndex === 6) { tempIndex = 6 };
    if (monthIndex === 7) { tempIndex = 7 };
    if (monthIndex === 8) { tempIndex = 8 };
    if (monthIndex === 9) { tempIndex = 9 };
    if (monthIndex === 10) { tempIndex = 10 };
    if (monthIndex === 11) { tempIndex = 11 };

    if (tempIndex > -1) {
        this.monthName = (shorten) ? monthsNames[tempIndex].substring(0, 3) : monthsNames[tempIndex];
    } else {
        this.monthName = "";
    }

    return this.monthName;
};

function DateString(props) {
    const dateString = props.children
    const date = new Date(dateString.replace(/-/g, "/"))
    if (dateString) {
        return (
            <span>{date.getOrdinalDate()} {date.getMonthName()}, {date.getFullYear()}</span>
        )
    } else {
        return new Date()
    }
}

export default DateString