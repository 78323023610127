import { useContext } from 'react'
import { UserContext } from '../../../contexts/user';

function Waiver(props) {
    const { user } = useContext(UserContext);
    const agree = async (e) => {
        e.preventDefault()
        props.setView('payment')
    }

    return (
        <form id='triba-waiver' onSubmit={agree}>
            <p>Great, thanks {user.firstname}. Now please read through this waiver.</p>
            <div id="triba-waiver-wrapper">
                {/* <h3>Waiver</h3> */}
                <p id="waiver-content">I hereby agree to the following:<br />
                    &nbsp; <br />
                    That I am participating in the Yoga classes or any other exercise programs offered by Revitalize Studios during which I will receive information and instruction about yoga, physical exercise or health.I recognise that exercise requires physical exertion, which may be strenuous and may cause physical injury, and I am fully aware of the risks and hazards involved.<br />
                    &nbsp; <br />
                    I understand that it is my responsibility to consult a physician prior to and regarding my participation in the yoga classes, health programs or workshops offered by Revitalize Studios.I represent and warrant that I am physically fit and I have no medical condition, which would prevent my full participation in these yoga classes, health programs or workshops.<br />
                    &nbsp; <br />
                    It is my responsibility to opt out from practices at any point, which I deem unfit or unsuitable during the practice.Therefore, in consideration of being permitted to participate in the yoga sessions, I agree to assume full responsibility for any risks, injuries or damages, known or unknown, which I might incur as a result of participating in activities offered by Revitalize Studios.<br />
                    &nbsp; <br />
                    If I am pregnant I understand that I participate fully at my own risk and that of my unborn child/children.<br />
                    &nbsp; <br />
                    In consideration of being permitted to participate in the yoga classes, health programs or workshops, I agree to assume full responsibility for any risks, injuries or damages, known or unknown, which I might incur as a result of participating in the programs offered by Revitalize Studios.<br />
                    &nbsp; <br />
                    In further consideration of being permitted to participate in the yoga classes, health programs or workshops, I knowingly, voluntarily and expressly waive any claim I may have against Revitalize Studios or the instructor for injury or damages that I may sustain as a result of participating in these programs.<br />
                    &nbsp; <br />
                    I understand that from time to time during classes, the instructor may physically adjust students form and posture.If I do not want such physical adjustments, I will so inform the instructor at each class I attend.I also acknowledge that if I do wish to receive such adjustments, it is my responsibility to inform the instructor when an adjustment has gone as far as I desire at that time.<br />
                    &nbsp; <br />
                    I hereby take full and sole responsibility from any liability of loss or damage to personal property associated with yoga classes or any other events.<br />
                    &nbsp; <br />
                    I, my heirs or legal representatives forever release, waive, discharge and covenant not to sue Revitalize Studios or its employees for any injury caused by their negligence or other acts.<br />
                    <br />
                    I have read the above release and waiver of liability and fully understand its contents.<br />
                    &nbsp; <br />
                    Upon booking a class with Revitalize Studios, I voluntarily agree to the terms and conditions stated above under my own free will.<br />
                </p>
                <div className="formbuttons">
                    <button type='submit'>I agree to this waiver</button>
                </div>
            </div>
        </form>
    )
}

export default Waiver;