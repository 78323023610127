import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserContextProvider from './contexts/user/user-context';
import ClassContextProvider from './contexts/class/class-context'
import CustomizeContextProvider from './contexts/customize/customize-context'
import { init } from '@amplitude/analytics-browser';

import reportWebVitals from './reportWebVitals';
init('c5cbcc970b8e7ba85b00e8e59dc9756f');

ReactDOM.render(
  <React.StrictMode>
    <CustomizeContextProvider>
      <ClassContextProvider>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </ClassContextProvider>
    </CustomizeContextProvider>
  </React.StrictMode>,
  document.getElementById('triba-calendar')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
