import React, { useContext } from 'react';
import { useState } from 'react';
import {
    CardElement,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import Price from '../../price/price';
import Loader from '../../loader/loader';
import { ClassContext } from '../../../contexts/class';
import { UserContext } from '../../../contexts/user';
import * as amplitude from '@amplitude/analytics-browser'

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: 'Sen "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

const CheckoutForm = (props) => {
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');
    const [paying, setPaying] = useState(false);
    const [dicounted, setDiscounted] = useState(false);
    const { clientSecret } = props;
    const { tribaClass } = useContext(ClassContext)
    const { user } = useContext(UserContext)
    const stripe = useStripe();
    const elements = useElements();

    let price = tribaClass.price
    if (user.intro && tribaClass.intro_price !== 0) {
        setDiscounted(true)
        price = tribaClass.intro_price
    }

    if(tribaClass.classtype === 'stream') {
        price = tribaClass.stream_price
    }

    // Handle real-time validation errors from the card Element.
    const handleChange = (event) => {
        if (event.error) {
            setMessage(null)
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setPaying(true)

        let student = user

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    email: student.email
                },
            },
            receipt_email: student.email
        });

        if (result.error) {
            setMessage(null)
            // Show error to your customer (e.g., insufficient funds)
            setError(result.error.message)
        } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
                try {
                    setPaying(false)
                    amplitude.track('Booked', {type: 'payment'});
                    props.setView('success')
                } catch (error) {
                    setPaying(false)
                    setError(`Your payment has gone through, but we couldn't make a booking - please contact us.`)
                }

            }
        }
    };

    let button = <span className='loadingButton' type='disabled'><Loader /></span>
    if (!paying) {
        button = <button className="btn" type="submit">
            Pay&nbsp;<Price>{price}</Price></button>
    }

    let discount = null

    if (dicounted) {
        discount = <p>We've dicounted your first class. <span>{tribaClass.price}</span></p>
    }

    return (
        <div>
            <form id="paymentform" onSubmit={handleSubmit}>
                <div className="form-row">
                    <label htmlFor="email"></label>
                    <CardElement
                        id="card-element"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleChange}
                    />
                    <div className="triba-card-errors" role="alert">{error}</div>
                    <div className="triba-payment-message">{message}</div>
                </div>
                {discount}
                {button}
            </form>
        </div>
    );
}

export default CheckoutForm
